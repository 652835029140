import React from "react"

const Studio31 = ({ className }) => {
  return (
    <svg
      width="260"
      height="70.855"
      viewBox="0 0 260 70.855"
      className={className}
    >
      <path
        d="M26.857-130.053C14.505-127.073,3.832-116.292.907-103.832A43.48,43.48,0,0,0,.853-87.308c4.5,19.016,24.379,31.1,43.287,26.276a32.859,32.859,0,0,0,16.307-9.481C71.77-81.782,74.208-97.872,66.84-112.121c-2.817-5.526-9.86-12.461-15.332-15.115a38.586,38.586,0,0,0-7.585-2.817A47.541,47.541,0,0,0,26.857-130.053ZM46.794-111.9a76,76,0,0,1-7.476,3.2c-3.847,1.517-7.26,2.98-7.476,3.2-.271.271,3.142,1.9,7.585,3.684l8.018,3.2.054,2.98a17.331,17.331,0,0,1-.163,3.251c-.108.325-10.727-3.847-16.47-6.447-1.409-.65-3.684-1.625-5.147-2.167L23.065-102.1,22.9-105.24l-.163-3.2,12.081-4.93L46.9-118.351l.163,3.034A8.4,8.4,0,0,1,46.794-111.9ZM32.654-95.055c4.28,1.788,9.264,3.9,11.16,4.659l3.413,1.409-.163,3.251L46.9-82.486,35.255-77.718c-6.393,2.6-11.865,4.768-12.081,4.768s-.379-1.409-.271-3.142l.163-3.088,7.585-3.088c4.172-1.679,7.8-3.2,8.072-3.3S35.58-87.2,31.137-89.041l-8.072-3.251L22.9-95.651C22.686-99.66,21.656-99.714,32.654-95.055Z"
        transform="translate(-0.087 130.825)"
        fill="currentColor"
      />
      <path
        d="M297.637-98.242a39.547,39.547,0,0,0-.108,6.447c.163,5.743.163,5.743-.921,4.713A12.433,12.433,0,0,0,290-89.79c-2.167.054-5.58,1.734-7.531,3.738-2,2.059-3.467,6.176-3.467,9.806a14.924,14.924,0,0,0,5.472,10.51c3.9,2.709,9.372,2.6,12.677-.325,1.354-1.138,1.9-1.409,1.9-.813,0,1.625,2.709,3.142,5.364,2.98l2.492-.163.163-3.142c.108-2.113-.108-3.467-.65-4.28-.7-1.029-.867-3.359-.867-14.357V-99h-3.792C298.991-99,297.853-98.783,297.637-98.242ZM296.12-80.905c1.625,1.625,1.842,2.221,1.842,4.551,0,3.576-1.788,5.526-5.093,5.526-2.871,0-4.5-1.138-5.2-3.684-1.084-4.117,1.409-8.235,4.984-8.235A5.016,5.016,0,0,1,296.12-80.905Z"
        transform="translate(-127.895 116.242)"
        fill="currentColor"
      />
      <path
        d="M334.379-98.621A11.165,11.165,0,0,0,334-94.828v3.413h8.126V-99h-3.684A12.586,12.586,0,0,0,334.379-98.621Z"
        transform="translate(-153.098 116.242)"
        fill="currentColor"
      />
      <path
        d="M156.777-96.272c-2.6.758-6.447,4.822-6.935,7.26-1.192,6.393,1.409,10.023,8.939,12.406,5.743,1.842,7.639,2.817,7.639,4.063,0,3.467-7.531,2.709-14.628-1.409-.163-.108-1.084,1.409-2.059,3.359L148-67.07l1.3.813c2.221,1.463,7.476,3.034,11.16,3.359,4.713.379,8.777-.6,11.215-2.709a8.761,8.761,0,0,0,3.413-7.422c0-5.418-2.492-7.91-10.348-10.348-6.989-2.167-8.072-3.2-5.851-5.418,1.463-1.463,4.5-1.192,8.777.758l3.034,1.354,1.679-2.98c.921-1.625,1.571-3.3,1.409-3.684C172.921-95.621,161.165-97.626,156.777-96.272Z"
        transform="translate(-67.866 115.193)"
        fill="currentColor"
      />
      <path
        d="M200.2-91.208v3.792h-3.3l.163,3.088c.163,3.034.217,3.142,1.679,3.142l1.463-.054v7.26c0,8.018.488,9.806,3.251,11.215,2.709,1.409,10.51.6,11.215-1.138a8.575,8.575,0,0,0-.379-3.359l-.6-2.6h-2.546l-2.546.054L208.44-75.5l-.163-5.689,2.492.163,2.438.217v-6.61h-4.876V-95H200.2Z"
        transform="translate(-90.274 114.409)"
        fill="currentColor"
      />
      <path
        d="M403.255-84.2c-2.438.758-5.634,2.926-5.634,3.792a14.182,14.182,0,0,0,2.438,3.2l2.33,2.546,1.625-1.625a4.78,4.78,0,0,1,4.117-1.625c3.792,0,5.418,2.492,3.034,4.876a4.577,4.577,0,0,1-3.521,1.084H405.2V-66.1l2.6.379c3.63.488,4.984,1.517,4.984,3.738,0,2.6-1.354,3.576-4.93,3.576a9.057,9.057,0,0,1-4.713-1.084c-2.113-1.3-2.438-1.138-4.768,2.221-1.517,2.167-1.517,2.221-.488,3.034a17.16,17.16,0,0,0,8.993,3.3c6.664.488,12.19-2.221,13.761-6.664.433-1.192.758-1.734.813-1.246,0,.921.867.975,10.294.975h10.294v-7.043h-5.689V-84.684l-3.9-.163c-3.467-.108-4.117,0-5.147,1.029a20.728,20.728,0,0,1-4.063,2.492c-2.113.975-2.763,1.517-2.492,2.221.163.488-.217.163-.813-.7a10.089,10.089,0,0,0-5.147-4.334C412.41-85.117,406.4-85.171,403.255-84.2Zm21.616,19.178-3.413.163-.054,1.734-.054,1.734-.433-1.788c-.488-2.059-3.521-5.526-4.822-5.526-.488,0-.108-.488.867-1.084a8.791,8.791,0,0,0,2.871-3.088c.65-1.138,1.029-1.625.867-1.138-.433,1.409,1.571,1.246,4.551-.271l2.438-1.192.271,5.147.271,5.147Z"
        transform="translate(-182.045 109.781)"
        fill="currentColor"
      />
      <path
        d="M360.9-81.386C353.53-79,349.791-72.175,351.9-64.97a13.306,13.306,0,0,0,7.043,8.072,16.741,16.741,0,0,0,11.485,0,13.033,13.033,0,0,0,.488-23.458C368.591-81.548,363.119-82.144,360.9-81.386Zm7.1,8.126c1.3,1.3,1.571,2.059,1.571,4.117,0,2.763-1.084,5.363-2.438,5.905a6.847,6.847,0,0,1-5.689-.65,5.5,5.5,0,0,1-1.571-2.817C358.568-72.88,363.986-77.268,367.995-73.259Z"
        transform="translate(-161.034 108.325)"
        fill="currentColor"
      />
      <path
        d="M231-71.519c0,10.294.325,11.919,2.926,14.3,2.763,2.546,10.348,1.95,13.057-1.029.975-1.084.975-1.084,1.95.542,1.138,1.788,2.546,2.33,5.689,2.059l2.113-.163L256.9-59c.108-2.492-.054-3.359-.65-3.684-.758-.433-.867-1.9-.867-9.373V-81h-8.126v7.856c0,7.693-.054,7.964-1.354,9.21-1.679,1.734-5.363,1.9-6.23.325-.271-.6-.542-4.659-.542-9.21V-81H231Z"
        transform="translate(-105.9 107.993)"
        fill="currentColor"
      />
      <path
        d="M334-68.539v12.461h8.126V-81H334Z"
        transform="translate(-153.098 107.993)"
        fill="currentColor"
      />
    </svg>
  )
}

export default Studio31
