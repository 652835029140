import React from "react"

const Pineapple = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 111.69 207.47"
      width="1em"
      height="1em"
      className={className}
    >
      <g id="Capa_2" data-name="Capa 2">
        <g id="Capa_1-2" data-name="Capa 1">
          <g id="hojas">
            <path
              d="M34.09.39c1.31,16.17,1,25.16-1.4,41.19q2.09,1,4.11,2Q45,31.83,54.34,20.69a43.44,43.44,0,0,0-2.59-5.64C48.32,8.93,43.6,4.51,34.09.39Z"
              fill="#2c5530"
              stroke="#2c5530"
              stroke-width="0.5"
            />
            <path
              d="M7.22,39.62C14.36,46.52,22.11,53,30.07,59.37A60.81,60.81,0,0,1,56,69l-3.64-5.36c-3-4.46-13.87-11.7-25.45-17a120.33,120.33,0,0,0-19.7-7Z"
              fill="#2c5530"
              stroke="#2c5530"
              stroke-width="0.5"
            />
            <path
              d="M.81,69.41c14.58,4,31.26,9.82,44.74,16.57a26.93,26.93,0,0,1,11.64-3.64,26.74,26.74,0,0,1,11.08,2c11.24-8.83,25.35-18.18,38.87-24.52-16.5-1.9-32,1.22-47.56,16.1l-2.65,2.52-3-2.24C36.26,62.93,16,63.63.81,69.41Z"
              fill="#2c5530"
              stroke="#2c5530"
              stroke-width="0.5"
            />
            <path
              d="M82.73,1.27a307.58,307.58,0,0,0-39.18,46,66.42,66.42,0,0,1,11.77,8.48c4.06-5.33,10.31-11.09,17.33-16.46Z"
              fill="#2c5530"
              stroke="#2c5530"
              stroke-width="0.5"
            />
            <path
              d="M73.92,47.62C67,53.46,61.4,59.49,59.49,63.26l-2.77,5.48A66.16,66.16,0,0,1,76.43,56.88L96.65,32.46c-.17.08-.33.14-.5.23a109.2,109.2,0,0,0-15,9.24c-2.5,1.81-5,3.74-7.25,5.69Z"
              fill="#2c5530"
              stroke="#2c5530"
              stroke-width="0.5"
            />
          </g>
          <g id="cuerpo">
            <path
              d="M25.67,104.29c-.7.94-1.39,1.89-2.07,2.87q6.51,3.21,13,6.53,5.52-3.64,11.11-7.2-6.35-3.1-12.79-6.06l-9.21,3.86Z"
              fill="#ffaf29"
              stroke="#ffaf29"
              stroke-width="0.5"
            />
            <path
              d="M19.59,113.3a117.55,117.55,0,0,0-6.51,12.29l3.72,1.82q6.33-4.64,12.84-9.07c-3.34-1.7-6.68-3.38-10-5Z"
              fill="#ffaf29"
              stroke="#ffaf29"
              stroke-width="0.5"
            />
            <path
              d="M44,96.63c3.68,1.73,7.33,3.51,11,5.31q4.8-3,9.63-5.91L55,92,44,96.63Z"
              fill="#ffaf29"
              stroke="#ffaf29"
              stroke-width="0.5"
            />
            <path
              d="M7.47,143.89l12.94,7.24c3.48-2.73,7.07-5.55,10.72-8.4Q24.44,139.31,17.7,136q-5.19,3.89-10.23,7.92Z"
              fill="#ffaf29"
              stroke="#ffaf29"
              stroke-width="0.5"
            />
            <path
              d="M24.31,131.12q6.61,3.28,13.17,6.67,5.81-4.47,11.93-9-6.1-3.33-12.3-6.56-6.48,4.36-12.8,8.92Z"
              fill="#ffaf29"
              stroke="#ffaf29"
              stroke-width="0.5"
            />
            <path
              d="M5.66,151.27A39.74,39.74,0,0,0,6,162.36q4-3.08,8.17-6.33l-8.51-4.76Z"
              fill="#ffaf29"
              stroke="#ffaf29"
              stroke-width="0.5"
            />
            <path
              d="M44,117.59q6,3.15,11.91,6.39,5.4-3.9,10.92-7.68-5.72-3-11.49-6-5.71,3.57-11.34,7.25Z"
              fill="#ffaf29"
              stroke="#ffaf29"
              stroke-width="0.5"
            />
            <path
              d="M62.61,105.83q5.6,2.88,11.13,5.85,5.46-3.6,11-7l-11.9-5.07q-5.14,3.11-10.27,6.26Z"
              fill="#ffaf29"
              stroke="#ffaf29"
              stroke-width="0.5"
            />
            <path
              d="M13.25,166.16c4.1,1.83,8.1,3.78,12,5.83q3.63-2.88,7.27-5.67L21.21,160q-4,3.11-8,6.18Z"
              fill="#ffaf29"
              stroke="#ffaf29"
              stroke-width="0.5"
            />
            <path
              d="M27.46,155.08,39,161.52q5.85-4.31,11.83-8.45-6.16-3.33-12.38-6.57-5.49,4.27-11,8.58Z"
              fill="#ffaf29"
              stroke="#ffaf29"
              stroke-width="0.5"
            />
            <path
              d="M9.39,172.39a58.09,58.09,0,0,0,5,8.46q2.3-1.93,4.61-3.84c-3.18-1.61-6.39-3.16-9.66-4.62Z"
              fill="#ffaf29"
              stroke="#ffaf29"
              stroke-width="0.5"
            />
            <path
              d="M44.77,141.58q6.45,3.39,12.83,6.86,6.19-4.14,12.51-8.11-6.72-3.87-13.52-7.64-6.08,4.47-11.82,8.89Z"
              fill="#ffaf29"
              stroke="#ffaf29"
              stroke-width="0.5"
            />
            <path
              d="M63.12,128q7.07,3.95,14.06,8,6.07-3.72,12.24-7.31c-5-2.82-10.12-5.63-15.21-8.39Q68.62,124,63.12,128Z"
              fill="#ffaf29"
              stroke="#ffaf29"
              stroke-width="0.5"
            />
            <path
              d="M81.12,115.66Q89,120,96.83,124.39l3.59-2a119.4,119.4,0,0,0-9.08-13.12c-3.44,2.09-6.85,4.24-10.22,6.42Z"
              fill="#ffaf29"
              stroke="#ffaf29"
              stroke-width="0.5"
            />
            <path
              d="M19.13,186.67a69.72,69.72,0,0,0,7.4,7.06c.41.34.84.68,1.27,1q4.67-3.84,9.34-7.67c-3.61-2.15-7.26-4.25-10.94-6.27q-3.54,2.88-7.07,5.87Z"
              fill="#ffaf29"
              stroke="#ffaf29"
              stroke-width="0.5"
            />
            <path
              d="M32.42,175.83c3.66,2,7.26,4.14,10.83,6.28q3.59-2.91,7.21-5.77l-10.83-6.06Q36,173,32.42,175.83Z"
              fill="#ffaf29"
              stroke="#ffaf29"
              stroke-width="0.5"
            />
            <path
              d="M46.08,165.5l10.62,5.94q5.71-4.42,11.5-8.7-5.06-2.88-10.14-5.7-6.06,4.14-12,8.46Z"
              fill="#ffaf29"
              stroke="#ffaf29"
              stroke-width="0.5"
            />
            <path
              d="M64.88,152.46q5,2.76,9.84,5.58,5.85-4.14,11.88-8-4.63-2.78-9.3-5.5-6.27,3.91-12.42,8Z"
              fill="#ffaf29"
              stroke="#ffaf29"
              stroke-width="0.5"
            />
            <path
              d="M84.39,140.14q4.6,2.72,9.18,5.46,6.36-3.87,12.88-7.36l-9.6-5.44q-6.28,3.62-12.46,7.34Z"
              fill="#ffaf29"
              stroke="#ffaf29"
              stroke-width="0.5"
            />
            <path
              d="M34.17,199.17a59.6,59.6,0,0,0,11.62,5.54l8.62-7q-5.23-3.31-10.49-6.56c-3.25,2.64-6.5,5.31-9.75,8Z"
              fill="#ffaf29"
              stroke="#ffaf29"
              stroke-width="0.5"
            />
            <path
              d="M50,186.24c3.53,2.19,7,4.39,10.49,6.6q4-3.22,8.05-6.37l-11.05-6.18q-3.75,3-7.49,6Z"
              fill="#ffaf29"
              stroke="#ffaf29"
              stroke-width="0.5"
            />
            <path
              d="M63.78,175.4l11.11,6.22c3.87-2.91,7.68-5.67,11.44-8.21-3.7-2.24-7.39-4.43-11.09-6.6-3.84,2.79-7.66,5.67-11.46,8.59Z"
              fill="#ffaf29"
              stroke="#ffaf29"
              stroke-width="0.5"
            />
            <path
              d="M81.79,162.15q5.74,3.38,11.43,6.85a81.37,81.37,0,0,1,13.58-6.68q-6.54-4.08-13.15-8.08-6,3.84-11.86,7.91Z"
              fill="#ffaf29"
              stroke="#ffaf29"
              stroke-width="0.5"
            />
            <path
              d="M100.68,149.91q5.38,3.27,10.72,6.6A41.25,41.25,0,0,0,110,144.6q-4.71,2.53-9.35,5.31Z"
              fill="#ffaf29"
              stroke="#ffaf29"
              stroke-width="0.5"
            />
            <path
              d="M55,206.89a36.25,36.25,0,0,0,13-.59q-3.47-2.16-6.91-4.34L55,206.89Z"
              fill="#ffaf29"
              stroke="#ffaf29"
              stroke-width="0.5"
            />
            <path
              d="M67.17,197.05q4.89,3.1,9.84,6.15A56.6,56.6,0,0,0,87.32,197L75.6,190.42q-4.25,3.29-8.43,6.63Z"
              fill="#ffaf29"
              stroke="#ffaf29"
              stroke-width="0.5"
            />
            <path
              d="M82,185.59l11.27,6.31c.62-.59,1.23-1.2,1.82-1.82a67.21,67.21,0,0,0,6.15-7.4q-3.92-2.51-7.87-5c-3.68,2.36-7.5,5-11.37,7.86Z"
              fill="#ffaf29"
              stroke="#ffaf29"
              stroke-width="0.5"
            />
            <path
              d="M100.45,173.47c1.57,1,3.14,2,4.72,3a57.06,57.06,0,0,0,3.27-6.92A80.3,80.3,0,0,0,100.45,173.47Z"
              fill="#ffaf29"
              stroke="#ffaf29"
              stroke-width="0.5"
            />
          </g>
          <path
            id="lentes"
            d="M110.39,117H61.64v0h-7.5v0H5.39v11.4H8l1.17,5.11c3.75,16,9.49,21.49,22.5,21.49s22.5-4.13,22.5-21.49v-5.11s.35-3.8,3.75-3.8,3.75,3.8,3.75,3.8v5.18c0,17.34,9.87,21.42,22.73,21.42s18.52-5.94,22.27-21.42l1.17-5.18h2.58Z"
          />
        </g>
      </g>
    </svg>
  )
}

export default Pineapple
